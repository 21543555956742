import { createSelector, } from 'reselect';
import { takeLast, map, pipe, } from 'ramda';
import { selectors as bootstrapSelectors, } from '@ezugi/bootstrap';
import { getCardUIName, } from '../../util/cards';
import { LAST_CARDS_STATS, } from '../../constants/ui';

const { gameIdSelector, } = bootstrapSelectors;

const selectGame = (state) => state.game;

export const lastGameResultSelector = createSelector(
  selectGame,
  (game) => game.gameResults,
);

const selectStats = createSelector(
  selectGame,
  ({ stats, }) => stats || {},
);

export const abStatsSelector = createSelector(
  selectStats,
  ({ ab, }) => ab,
);

export const jokerStatsSelector = createSelector(
  selectStats,
  ({ joker, }) => joker,
);

export const jokerCardsHistSelector = createSelector(
  selectGame,
  ({ history = [], }) => pipe(
    map(({ JokerCard, WinningHand, }) => ({
      card: getCardUIName(JokerCard),
      winner: WinningHand,
    })),
    takeLast(LAST_CARDS_STATS),
  )(history)
);

export const historySelector = createSelector(
  selectGame,
  ({ history, }) => history || [],
);

export const gameTypeSelector = createSelector(
  gameIdSelector,
  (gameId) => Number(gameId)
);

export const isABSelector = createSelector(
  gameTypeSelector,
  (gameId) => gameId === 38
);

export const isOTTABSelector = createSelector(
  gameTypeSelector,
  (gameId) => gameId === 39
);
