import { createSelector, } from 'reselect';
import { propOr, prop, } from 'ramda';

import { ANDAR_HAND, BAHAR_HAND, JOKER_HAND, } from '../../constants/strings';

export const cardsSelector = prop('cards');

export const andarCardsSelector = createSelector(
  cardsSelector,
  propOr({ cards: [], }, ANDAR_HAND),
);

export const baharCardsSelector = createSelector(
  cardsSelector,
  propOr({ cards: [], }, BAHAR_HAND),
);

export const jokerCardsSelector = createSelector(
  cardsSelector,
  propOr({ cards: [], }, JOKER_HAND),
);

export const allCardsSelector = createSelector(
  andarCardsSelector,
  baharCardsSelector,
  jokerCardsSelector,
  (andar, bahar, joker) => ({
    [ANDAR_HAND]: andar,
    [BAHAR_HAND]: bahar,
    [JOKER_HAND]: joker,
  }),
);

export const noOfAndarBaharCardsSelector = createSelector(
  andarCardsSelector,
  baharCardsSelector,
  ({ cards: andarCards, }, { cards: baharCards, }) => andarCards.length + baharCards.length,
);
