import { of, } from 'rxjs';
import { actions as bootstrapActions, } from '@ezugi/bootstrap';
import { PLACE_YOUR_BETS, SHUFFLE, } from '@ezugi/constants';

const {
  roundActions: { round, },
} = bootstrapActions;

export default function handleRoundStatus(socketMessage) {
  const { MessageType, gameStep, } = socketMessage;
  const canPlaceBets = [ SHUFFLE, PLACE_YOUR_BETS, ].includes(MessageType);
  return of(
    round.set({
      roundStatus: MessageType,
      canPlaceBets,
      ...(canPlaceBets ? { winnerList: [], } : {}),
      ...(gameStep ? { gameStep, } : { gameStep: null, }), // used in OTTAB for PYB rounds
    }),
  );
}
