import { isEmpty, not, path, pathOr, } from 'ramda';
import { createSelector, } from 'reselect';
import { LAST_BETS, PLACE_YOUR_BETS, SHUFFLE, } from '@ezugi/constants';
import { jokerCardsSelector, } from './cards';
import { PYB_ROUND, } from '../../util/round';

export const roundSelector = path([ 'round', ]);
export const roundIdSelector = path([ 'round', 'roundId', ]);
export const roundStatusSelector = pathOr('', [ 'round', 'roundStatus', ]);
export const previousRoundStatusSelector = pathOr('', [ 'round', 'previousRoundStatus', ]);
export const canPlaceBetsSelector = pathOr(false, [ 'round', 'canPlaceBets', ]);
export const idleGameBoardSelector = createSelector(canPlaceBetsSelector, not);
export const roundHistorySelector = path([ 'round', 'roundHistory', ]);
export const gameResultsSelector = path([ 'round', 'gameResults', ]);
export const roundGameStepSelector = path([ 'round', 'gameStep', ]);

export const lastWinSelector = createSelector(
  gameResultsSelector,
  (result) => result.lastWin
);

export const isBetTimeSelector = createSelector(
  roundStatusSelector,
  (roundStatus) => [ SHUFFLE, PLACE_YOUR_BETS, LAST_BETS, ].includes(roundStatus)
);

export const isShuffleRoundSelector = createSelector(
  roundStatusSelector,
  jokerCardsSelector,
  (roundStatus = SHUFFLE, jokerCards) => [ SHUFFLE, ].includes(roundStatus) && isEmpty(jokerCards.cards || [])
);

export const isFirstPYBSelector = createSelector(
  roundGameStepSelector,
  (gameStep) => gameStep === PYB_ROUND.FIRST_STEP
);
export const isSecondPYBSelector = createSelector(
  roundGameStepSelector,
  (gameStep) => gameStep === PYB_ROUND.SECOND_STEP
);

export const isFirstBetTimeSelector = createSelector(
  isBetTimeSelector,
  isSecondPYBSelector,
  (isBetTime, isSecondPYB) => isBetTime && !isSecondPYB
);
