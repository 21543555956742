import { concat, of, } from 'rxjs';
import { last, propOr, } from 'ramda';

import { actions, } from '@ezugi/bootstrap';
import { GAME_RESULT, } from '@ezugi/constants';

import { historySelector, isOTTABSelector, } from '../../selectors/game';
import { getStats, } from '../game/utils';

const {
  roundActions: { round, },
  gameActions: { game, },
} = actions;

export default function handleGameResult(socketMessage, state) {
  const { roundId, timeStamp, WinAmount, GameResults, LastWinners, Statistics, } = socketMessage;
  const isOTTAB = isOTTABSelector(state);

  return concat(
    of(
      game.set({
        history: [ ...historySelector(state), GameResults, ],
        stats: {
          joker: getStats(propOr({}, 'JokerStatistics', Statistics), true),
          ab: getStats(propOr({}, 'winPercentages', Statistics)),
        },
        gameResults: GameResults,
        lastWin: WinAmount,
      })
    ),
    of(
      round.set({
        roundStatus: GAME_RESULT,
        roundId,
        winAmount: WinAmount,
        gameResults: GameResults,
        timestamp: timeStamp,
        winnerList: LastWinners,
        winningNumber: last(GameResults),
        ...(isOTTAB ? { gameStep: null, } : {}),
      })
    ),
  );
}
