import { all, equals, pipe, values, } from 'ramda';
import { ANDAR_HAND, BAHAR_HAND, } from '../../../../constants';

export const JOKER_STATS_INITIAL_DATA = {
  [ANDAR_HAND]: 50,
  [BAHAR_HAND]: 50,
};
export const STATS_INITIAL_DATA = {
  [ANDAR_HAND]: 0,
  [BAHAR_HAND]: 0,
};

export const mapStats = ({ Andar, Bahar, }) => ({
  [ANDAR_HAND]: Math.round(+Andar),
  [BAHAR_HAND]: Math.round(+Bahar),
});

const isInitialRound = pipe(
  values,
  all(equals(0))
);

// eslint-disable-next-line no-confusing-arrow
export const getStats = (stats = STATS_INITIAL_DATA, isJoker) => !isInitialRound(stats)
  ? mapStats(stats)
  : isJoker ? JOKER_STATS_INITIAL_DATA : STATS_INITIAL_DATA;
