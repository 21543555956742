import { ofType, combineEpics, } from 'redux-observable';
import { pluck, filter, flatMap, } from 'rxjs/operators';
import { actions, } from '@ezugi/bootstrap';
import {
  INITIAL_DATA, GAME_RESULT, CARD, SHUFFLE, CANCEL_LAST_CARD, PLACE_YOUR_BETS, NO_MORE_BETS,
} from '@ezugi/constants';

import handleInitialData from './handleInitialData';
import handleGameResult from './handleGameResult';
import handleCardMessage from './handleCardMessage';
import handleCancelLastCard from './handleCancelLastCard';
import handleRoundStatus from './handleRoundStatus';

const {
  socketActions: { socket, },
} = actions;

function initialDataEpic(action$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => MessageType === INITIAL_DATA),
    flatMap(handleInitialData),
  );
}

function gameResultEpic(action$, state$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => MessageType === GAME_RESULT),
    flatMap((socketMessage) => handleGameResult(socketMessage, state$.value)),
  );
}

function cardMessageEpic(action$, state$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => MessageType === CARD),
    flatMap((socketMessage) => handleCardMessage(socketMessage, state$.value)),
  );
}

function cancelLastCardMessageEpic(action$, state$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => MessageType === CANCEL_LAST_CARD),
    flatMap((socketMessage) => handleCancelLastCard(socketMessage, state$.value)),
  );
}

/**
 * This was added specifically for the Suffle state that is different for
 * Andar Bahar compared to the other games
 */
function roundStatusEpic(action$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => [ SHUFFLE, PLACE_YOUR_BETS, NO_MORE_BETS, ].includes(MessageType)),
    flatMap((socketMessage) => handleRoundStatus(socketMessage)),
  );
}

export default combineEpics(
  initialDataEpic,
  gameResultEpic,
  cardMessageEpic,
  cancelLastCardMessageEpic,
  roundStatusEpic,
);
