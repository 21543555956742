import { of, concat, } from 'rxjs';

import cardsActions from '../../actions/cards';
import { allCardsSelector, } from '../../selectors/cards';

export default function handleCardMessage(socketMessage, state) {
  const { CardHand, CardName, CardValue, } = socketMessage;

  const { cards, } = allCardsSelector(state)[CardHand];

  return concat(
    of(
      cardsActions.cards.set({
        [CardHand]: {
          cards: [
            ...cards,
            {
              CardName,
              CardValue,
            },
          ],
        },
      })
    )
  );
}
