import { handleActions, } from 'redux-actions';

import { INITIAL_STATE, } from '../../../constants/cards';
import cardsActions from '../../actions/cards';

export default handleActions(
  {
    [cardsActions.cards.set]: (state, { payload, }) => ({ ...state, ...payload, }),
    [cardsActions.cards.reset]: () => INITIAL_STATE,
  },
  INITIAL_STATE,
);
