import { of, } from 'rxjs';
import { LOADING, } from '@ezugi/constants';
import { actions as bootstrapActions, } from '@ezugi/bootstrap';
import { ls, } from '@ezugi/utils';

import { complement, filter, isEmpty, map, pipe, propOr, } from 'ramda';

import { getStats, } from '../game/utils';
import { getTotalBet, } from '../bets/utils';
import cardsActions from '../../actions/cards';
import { ANDAR_HAND, BAHAR_HAND, JOKER_HAND, } from '../../../constants';
import { NO_WELCOME_DIALOG, } from '../../../views/GameDialog/dialogs/Welcome/constants';

const {
  socketActions: { socket, },
  videoActions: { video, },
  roundActions: { round, },
  gameActions: { game, },
  settingsActions: { settings, },
  betActions: { bet, history, },
  dialogActions: { dialog, },
} = bootstrapActions;

const WELCOME_DIALOG = 'WelcomeDialog';

export default function handleInitialData(socketMessage) {
  const {
    VideoStreamData, roundId, timeStamp, RoundTripStartTime,
    AndarCards, BaharCards, JokerCard, PlayerBetOfThisRound,
    Statistics,
  } = socketMessage;
  const joker = getStats(propOr({}, 'JokerStatistics', Statistics), true);
  const ab = getStats(propOr({}, 'winPercentages', Statistics));
  const current = pipe(
    filter(complement(isEmpty)),
    map((value) => ({ value, valid: true, })),
  )({
    ...propOr({}, 'RegularBets', PlayerBetOfThisRound),
    ...propOr({}, 'SideBets', PlayerBetOfThisRound),
  });
  const [ err, noWelcomeDialog, ] = ls.get(NO_WELCOME_DIALOG);

  const UNKNOWN_CARD = 'ww';
  const historyObj = propOr([], 'lastWinners', Statistics).map((winningHand, index) => ({
    JokerCard: Statistics.lastJokers[index] || UNKNOWN_CARD,
    WinningHand: winningHand,
  }));

  const actions = [
    socket.success(socketMessage),
    settings.init(),
    video.set({ streamData: VideoStreamData, }),
    round.set({
      roundStatus: LOADING,
      roundId,
      roundTime: RoundTripStartTime,
      timestamp: timeStamp,
    }),
    game.set({
      gameResults: historyObj,
      history: historyObj,
      stats: {
        joker,
        ab,
      },
    }),
    cardsActions.cards.set({
      [ANDAR_HAND]: { cards: AndarCards, },
      [BAHAR_HAND]: { cards: BaharCards, },
      [JOKER_HAND]: { cards: ([ 'unknown', 'shuffle', ].includes(JokerCard.toLowerCase()))
        ? []
        : [ { CardName: JokerCard, }, ],
      },
    }),
  ];

  if (!isEmpty(current)) {
    actions.push(bet.apply({
      current,
      totalBet: getTotalBet(current),
    }));
    actions.push(
      // reset history to prevent sending bets to server again
      // because NO_MORE_BETS message comes after this actions
      history.reset()
    );
  }

  if (err || !noWelcomeDialog) {
    actions.push(dialog.add({ name: WELCOME_DIALOG, }));
  }

  return of(...actions);
}
