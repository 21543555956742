import { createSelector, } from 'reselect';
import { anyPass, compose, has, isEmpty, last, omit, prop, propOr, values, } from 'ramda';
import { GAME_RESULT, LAST_BETS, PLACE_YOUR_BETS, } from '@ezugi/constants';

import { MAIN_BETS, OTT_ANDARBAHAR_BET_TYPES, SIDEBETS_LIST, } from '../../constants/betTypes';
import { isOTTABSelector, } from './game';
import { isBetTimeSelector,
  isSecondPYBSelector,
  roundGameStepSelector,
  roundStatusSelector,
  isFirstBetTimeSelector,
} from './round';
import { getTotalBet, } from '../epics/bets/utils';
import { PYB_ROUND, } from '../../util/round';

const {
  ANDAR_FIRST,
  BAHAR_FIRST,
  ANDAR_SECOND,
  BAHAR_SECOND,
} = OTT_ANDARBAHAR_BET_TYPES;
const defaultValue = {};

const selectBets = (state) => state.bets || defaultValue;

export const allCurrentBetsSelector = createSelector(
  selectBets,
  propOr(defaultValue, 'current')
);

export const currentRoundBetsSelector = createSelector(
  allCurrentBetsSelector,
  isOTTABSelector,
  roundGameStepSelector,
  (currentBets, isOTTAB, gameStep) => {
    if (!isOTTAB || isEmpty(currentBets)) return currentBets; // AndarBahar

    switch (gameStep) { // OTTAndarBahar
    case PYB_ROUND.FIRST_STEP:
      return omit([ ANDAR_SECOND, BAHAR_SECOND, ], currentBets);
    case PYB_ROUND.SECOND_STEP:
      return omit([ ANDAR_FIRST, BAHAR_FIRST, ...SIDEBETS_LIST, ], currentBets);
    default: // SHUFFLE
      return omit([ ANDAR_SECOND, BAHAR_SECOND, ], currentBets);
    }
  }
);

export const sideBetsSelector = createSelector(
  allCurrentBetsSelector,
  omit(values(MAIN_BETS)),
);

export const allLastBetsSelector = createSelector(
  selectBets,
  propOr(defaultValue, 'last')
);

export const roundLastBetsSelector = createSelector(
  allLastBetsSelector,
  isOTTABSelector,
  roundGameStepSelector,
  (lastBets, isOTTAB, gameStep) => {
    if (!isOTTAB || isEmpty(lastBets)) return lastBets; // AndarBahar

    let cleanedLastBets = {};
    switch (gameStep) { // OTTAndarBahar
    case PYB_ROUND.FIRST_STEP:
      cleanedLastBets = omit([ ANDAR_SECOND, BAHAR_SECOND, ], lastBets.current);
      break;
    case PYB_ROUND.SECOND_STEP:
      cleanedLastBets = omit([ ANDAR_FIRST, BAHAR_FIRST, ...SIDEBETS_LIST, ], lastBets.current);
      break;
    default: // SHUFFLE
      cleanedLastBets = omit([ ANDAR_SECOND, BAHAR_SECOND, ], lastBets.current);
      break;
    }

    return {
      current: cleanedLastBets,
      totalBet: getTotalBet(cleanedLastBets),
    };
  }
);

export const betsHistorySelector = createSelector(
  selectBets,
  isOTTABSelector,
  roundGameStepSelector,
  (bets, isOTTAB, gameStep) => {
    const betsHistory = bets.history || defaultValue;
    if (!isOTTAB) return betsHistory; // AndarBahar

    let hasSecondBets = false;
    switch (gameStep) { // OTTAndarBahar
    case PYB_ROUND.FIRST_STEP:
      return betsHistory;
    case PYB_ROUND.SECOND_STEP:
      hasSecondBets = compose(
        anyPass([ has(ANDAR_SECOND), has(BAHAR_SECOND), ]),
        prop('current'),
        last,
      );
      return hasSecondBets(betsHistory) ? betsHistory : {};
    default:
      return betsHistory;
    }
  }
);

export const mainBetsWithoutSidebetsSelector = createSelector(
  allCurrentBetsSelector,
  omit(SIDEBETS_LIST)
);

export const firstMainBetsSelector = createSelector(
  allCurrentBetsSelector,
  omit([ ANDAR_SECOND, BAHAR_SECOND, ...SIDEBETS_LIST, ])
);

export const firstMainBetsWithSidebetsSelector = createSelector(
  allCurrentBetsSelector,
  omit([ ANDAR_SECOND, BAHAR_SECOND, ])
);

export const secondMainBetsSelector = createSelector(
  allCurrentBetsSelector,
  omit([ ANDAR_FIRST, BAHAR_FIRST, ...SIDEBETS_LIST, ])
);

export const totalFirstMainBetsSelector = createSelector(
  firstMainBetsSelector,
  (firstMainBets) => getTotalBet(firstMainBets)
);

export const totalFirstBetsSelector = createSelector(
  firstMainBetsWithSidebetsSelector,
  (firstBetsWithSidebets) => getTotalBet(firstBetsWithSidebets)
);

export const totalSecondBetsSelector = createSelector(
  secondMainBetsSelector,
  (secondBets) => getTotalBet(secondBets)
);

export const canPlaceFirstBetsSelector = createSelector(
  isBetTimeSelector,
  roundGameStepSelector,
  (isBetTime, gameStep) => isBetTime && gameStep !== PYB_ROUND.SECOND_STEP
);

export const canPlaceAndarFirstBetSelector = createSelector(
  isBetTimeSelector,
  firstMainBetsSelector,
  roundGameStepSelector,
  (isBetTime, firstMainBets, gameStep) => isBetTime && gameStep !== PYB_ROUND.SECOND_STEP
    && !has(BAHAR_FIRST, firstMainBets)
);

export const canPlaceBaharFirstBetSelector = createSelector(
  isBetTimeSelector,
  firstMainBetsSelector,
  roundGameStepSelector,
  (isBetTime, firstMainBets, gameStep) => isBetTime && gameStep !== PYB_ROUND.SECOND_STEP
    && !has(ANDAR_FIRST, firstMainBets)
);

export const canPlaceAndarSecondBetSelector = createSelector(
  roundStatusSelector,
  roundGameStepSelector,
  firstMainBetsSelector,
  (roundStatus, gameStep, firstMainBets) => [ PLACE_YOUR_BETS, LAST_BETS, ].includes(roundStatus)
    && gameStep !== PYB_ROUND.FIRST_STEP && has(ANDAR_FIRST, firstMainBets)
);

export const canPlaceBaharSecondBetSelector = createSelector(
  roundStatusSelector,
  roundGameStepSelector,
  firstMainBetsSelector,
  (roundStatus, gameStep, firstMainBets) => [ PLACE_YOUR_BETS, LAST_BETS, ].includes(roundStatus)
    && gameStep !== PYB_ROUND.FIRST_STEP && has(BAHAR_FIRST, firstMainBets)
);

/*
currently not used
export const isSecondBetDisabledSelector = createSelector(
  roundStatusSelector,
  isSecondPYBSelector,
  firstMainBetsSelector,
  secondMainBetsSelector,
  (roundStatus, isSecondPYB, firstBets, secondBets) => !isSecondPYB
    && roundStatus !== GAME_RESULT && isEmpty(secondBets),
);
*/

export const isAndarSecondBetDisabledSelector = createSelector(
  roundStatusSelector,
  isSecondPYBSelector,
  firstMainBetsSelector,
  secondMainBetsSelector,
  (roundStatus, isSecondPYB, firstBets, secondBets) => roundStatus !== GAME_RESULT
    && (!isSecondPYB || !has(ANDAR_FIRST, firstBets)) && !has(ANDAR_SECOND, secondBets)
);

export const isBaharSecondBetDisabledSelector = createSelector(
  roundStatusSelector,
  isSecondPYBSelector,
  firstMainBetsSelector,
  secondMainBetsSelector,
  (roundStatus, isSecondPYB, firstBets, secondBets) => roundStatus !== GAME_RESULT
    && (!isSecondPYB || !has(BAHAR_FIRST, firstBets)) && !has(BAHAR_SECOND, secondBets)
);


export const isAndarFirstBetDisabledSelector = createSelector(
  roundStatusSelector,
  isFirstBetTimeSelector,
  firstMainBetsSelector,
  (roundStatus, isFirstBetTime, firstBets) => (roundStatus !== GAME_RESULT
    && !isFirstBetTime && !has(ANDAR_FIRST, firstBets)) || has(BAHAR_FIRST, firstBets)
);

export const isBaharFirstBetDisabledSelector = createSelector(
  roundStatusSelector,
  isFirstBetTimeSelector,
  firstMainBetsSelector,
  (roundStatus, isFirstBetTime, firstBets) => (roundStatus !== GAME_RESULT
    && !isFirstBetTime && !has(BAHAR_FIRST, firstBets)) || has(ANDAR_FIRST, firstBets)
);

export const isGlowingAndarSecondBetSelector = createSelector(
  firstMainBetsSelector,
  isSecondPYBSelector,
  secondMainBetsSelector,
  (firstMainBets, isSecondPYB, secondBets) => isSecondPYB && has(ANDAR_FIRST, firstMainBets) && isEmpty(secondBets),
);

export const isGlowingBaharSecondBetSelector = createSelector(
  firstMainBetsSelector,
  isSecondPYBSelector,
  secondMainBetsSelector,
  (firstMainBets, isSecondPYB, secondBets) => isSecondPYB && has(BAHAR_FIRST, firstMainBets) && isEmpty(secondBets),
);
