import { ANDAR_BAHAR_BET_TYPES, } from '@ezugi/constants';

export const OTT_ANDARBAHAR_BET_TYPES = {
  ANDAR_FIRST: 'AndarFirstBet',
  ANDAR_SECOND: 'AndarSecondBet',
  BAHAR_FIRST: 'BaharFirstBet',
  BAHAR_SECOND: 'BaharSecondBet',
};

export const {
  ANDAR,
  BAHAR,
  ONE_TO_FIVE,
  SIX_TO_TEN,
  ELEVEN_TO_FIFTEEN,
  SIXTEEN_TO_TWENTYFIVE,
  TWENTYSIX_TO_THIRTY,
  THIRTYONE_TO_THIRTYFIVE,
  THIRTYSIX_TO_FOURTY,
  FOURTYONE_OR_MORE,
} = ANDAR_BAHAR_BET_TYPES;

export const MAIN_BETS = {
  ANDAR,
  BAHAR,
  ...OTT_ANDARBAHAR_BET_TYPES,
};

export const SIDEBETS = {
  ONE_TO_FIVE,
  SIX_TO_TEN,
  ELEVEN_TO_FIFTEEN,
  SIXTEEN_TO_TWENTYFIVE,
  TWENTYSIX_TO_THIRTY,
  THIRTYONE_TO_THIRTYFIVE,
  THIRTYSIX_TO_FOURTY,
  FOURTYONE_OR_MORE,
};

export const SIDEBETS_LIST = [
  ONE_TO_FIVE,
  SIX_TO_TEN,
  ELEVEN_TO_FIFTEEN,
  SIXTEEN_TO_TWENTYFIVE,
  TWENTYSIX_TO_THIRTY,
  THIRTYONE_TO_THIRTYFIVE,
  THIRTYSIX_TO_FOURTY,
  FOURTYONE_OR_MORE,
];

export const SIDEBETS_WINNERS = {
  [ONE_TO_FIVE]: [ 1, 5, ],
  [SIX_TO_TEN]: [ 6, 10, ],
  [ELEVEN_TO_FIFTEEN]: [ 11, 15, ],
  [SIXTEEN_TO_TWENTYFIVE]: [ 16, 25, ],
  [TWENTYSIX_TO_THIRTY]: [ 26, 30, ],
  [THIRTYONE_TO_THIRTYFIVE]: [ 31, 35, ],
  [THIRTYSIX_TO_FOURTY]: [ 36, 40, ],
  [FOURTYONE_OR_MORE]: [ 41, 51, ],
};

export default { ...ANDAR_BAHAR_BET_TYPES, ...OTT_ANDARBAHAR_BET_TYPES, };
