import { AB_COLORS, } from '@ezugi/constants';

const { BOARD_COLORS, ROADMAP_BORDER_COLOR, } = AB_COLORS;

export const SIDEBETS_TEXT_COLOR = '#dbdbdb';
export const OPACITY_LOW = 0.65;
export const OPACITY_HIGH = 0.85;
export const BORDER_COLOR = ROADMAP_BORDER_COLOR; // '#292D55';

export const colorsMap = {
  Andar: BOARD_COLORS.ANDAR,
  Bahar: BOARD_COLORS.BAHAR,
  JOKER: BOARD_COLORS.JOKER,
  SIDEBETS: BOARD_COLORS.SIDEBETS,
};
