import { actions, selectors, } from '@ezugi/bootstrap';
import { filter, isEmpty, mapObjIndexed, omit, pick, prop, values, } from 'ramda';

import { getTotalBet, } from '.';
import { MAIN_BETS, OTT_ANDARBAHAR_BET_TYPES, SIDEBETS, SIDEBETS_LIST, } from '../../../../constants/betTypes';
import { isOTTABSelector, } from '../../../selectors/game';
import { isSecondPYBSelector, } from '../../../selectors/round';
import { allCurrentBetsSelector, betsHistorySelector, } from '../../../selectors/bets';

const { socketActions, notificationActions, betActions, } = actions;
const { userSelector, tableIdSelector, roundIdSelector, gameSelector, } = selectors;
const {
  ANDAR_FIRST,
  BAHAR_FIRST,
} = OTT_ANDARBAHAR_BET_TYPES;

export const buildParams = (bets, state, seed) => {
  const user = userSelector(state);

  return {
    ClientId: user.clientId,
    ClientIP: user.clientIp,
    CurrentPlayerToken: user.currentPlayerToken,
    MessageType: 'PlaceBet',
    destination: 'player',
    gameType: gameSelector(state).serverName, // || 'OTTAndarBahar',
    TableId: tableIdSelector(state).toString(),
    roundId: roundIdSelector(state),
    ...bets,
    ...seed,
  };
};

const keepValidBets = filter(prop('valid'));

export const createBetsList = (bets) => {
  // Send bets
  const seed = mapObjIndexed(({ value, }) => value, bets);
  return { BetsList: pick(values(MAIN_BETS), seed), SideBets: pick(values(SIDEBETS), seed), };
};

export const createPlaceBetRequestPayload = (state) => {
  const _actions = [];
  const currentBetsList = allCurrentBetsSelector(state);
  const betHistory = betsHistorySelector(state);
  const isOTTAB = isOTTABSelector(state);
  const isSecondPYB = isSecondPYBSelector(state);
  const currentBets = values(currentBetsList);

  // in case of reconnection avoid resending bets
  if (betHistory.length <= 1) return _actions;

  if (currentBets.length) {
    let validBetsList = keepValidBets(currentBetsList);
    const totalBet = getTotalBet(validBetsList);

    if (currentBets.length > values(validBetsList).length) {
      // Remove invalid bets and update `TotalBet` and `BetHistory`
      _actions.push(notificationActions.notification.add({ message: 'notifications.bet_below_min_limits_removed', }));
      _actions.push(betActions.history.apply({ current: validBetsList, }));
      _actions.push(betActions.totalBet.set({ value: totalBet, }));
    }

    // Cache valid bets for `Rebet`
    _actions.push(betActions.bet.cache({ current: validBetsList, totalBet, }));

    if (isOTTAB && isSecondPYB) { // remove first bets and sidebets on 2nd PYB round
      validBetsList = omit([ ANDAR_FIRST, BAHAR_FIRST, ...SIDEBETS_LIST, ], validBetsList);
    }

    // Do not send empty bets
    if (isEmpty(validBetsList)) return _actions;

    // Send bets
    const betsList = createBetsList(validBetsList);
    const params = buildParams(betsList, state);
    _actions.push(socketActions.socket.send(params));
  }

  return _actions;
};

export const createCurrentBetRequestPayload = (state) => {
  const currentBetsList = allCurrentBetsSelector(state);
  const betsList = createBetsList(currentBetsList);

  return buildParams(betsList, state, {
    destination: 'table',
    MessageType: 'CurrentBet',
  });
};
