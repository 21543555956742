import { of, } from 'rxjs';
import { catchError, map, switchMap, } from 'rxjs/operators';
import { ofType, } from 'redux-observable';
import { actions, selectors, } from '@ezugi/bootstrap';

import { formatPayouts, } from './utils';

const { authSelector, gameSelector, } = selectors;
const {
  configActions: { config, },
  authActions: { auth, },
  notificationActions,
} = actions;

const {
  REACT_APP_API_PORT,
  REACT_APP_API_PROTOCOL,
  REACT_APP_API_HOST,
  REACT_APP_API_FETCH_PAYOUTS,
} = process.env;

const PORT = REACT_APP_API_PORT;
const BASE_URL = `${REACT_APP_API_PROTOCOL}://${REACT_APP_API_HOST}:${PORT}`;

const payoutsUrl = `${BASE_URL}${REACT_APP_API_FETCH_PAYOUTS}`;

function payoutsEpic(action$, state$) {
  return action$.pipe(
    ofType(auth.success),
    switchMap(() => {
      const { identifier, } = authSelector(state$.value);
      const { gameId, } = gameSelector(state$.value);

      return fetch(`${payoutsUrl}?game_id=${gameId}&identifier=${identifier}&raw=true`, {
        credentials: 'include',
      }).then((res) => res.json());
    }),
    map(({ result: { payouts, } = {}, }) => config.update({ payouts: formatPayouts(payouts), })),
    catchError(({ error_code: errorCode, error_message: errorMessage, }) => of(
      notificationActions.notification.add({ message: 'messages.get_payouts_failed', }),
      config.update({ payouts: { errorCode, errorMessage, }, })
    ))
  );
}

export default payoutsEpic;
