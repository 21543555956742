import { of, } from 'rxjs';
import { dropLast, } from 'ramda';

import cardsActions from '../../actions/cards';
import { allCardsSelector, } from '../../selectors/cards';

export default function handleCancelLastCard(socketMessage, state) {
  const { CardHand, } = socketMessage;

  const { cards, } = allCardsSelector(state)[CardHand];

  return of(
    cardsActions.cards.set({ [CardHand]: { cards: dropLast(1, cards), }, })
  );
}
