import { distinctUntilChanged, filter, flatMap, map, pluck, skipUntil, take, } from 'rxjs/operators';
import { concat, of, } from 'rxjs';
import { combineEpics, ofType, } from 'redux-observable';
import { equals, includes, isEmpty, length, map as rMap, } from 'ramda';
import { actions as bootstrapActions, } from '@ezugi/bootstrap';
import { GAME_RESULT, NO_MORE_BETS, PLACE_YOUR_BETS, SHUFFLE, } from '@ezugi/constants';

import { allCurrentBetsSelector, } from '../../selectors/bets';
import { getTotalBet, } from './utils';
import { createPlaceBetRequestPayload, } from './utils/request';
import { validateBet, validateBetUndo, validateDouble, validateRebet, } from './utils/validations';

const { roundActions, betActions, } = bootstrapActions;
const { round, } = roundActions;
const { bet, totalBet, history, } = betActions;

const placeBetEpic = (action$, state$) => action$.pipe(
  ofType(bet.request),
  pluck('payload'),
  map((bets) => validateBet(bets, state$.value)),
  flatMap(({ valid, bet: _bet, ...result }) => {
    const actions = [ ...(valid && _bet && !isEmpty(_bet) ? [ of(bet.set(_bet)), ] : []), ];

    actions.push(...rMap(of, result.actions));

    return concat(...actions);
  })
);

const betResetEpic = (action$) => action$.pipe(
  ofType(round.set),
  pluck('payload'),
  filter(({ roundStatus, }) => [ SHUFFLE, GAME_RESULT, ].includes(roundStatus)),
  flatMap(() => concat(of(bet.reset())), of(history.reset()))
);

const totalBetEpic = (action$, state$) => action$.pipe(
  ofType(bet.set),
  map(() => {
    const currentBets = allCurrentBetsSelector(state$.value);
    return totalBet.set({ value: getTotalBet(currentBets), });
  })
);

const isBetStates = (args) => includes(args, [ SHUFFLE, PLACE_YOUR_BETS, ]);

const betRequestEpic = (action$, state$) => action$.pipe(
  ofType(round.set),
  pluck('payload', 'roundStatus'),
  distinctUntilChanged(),
  filter(equals(NO_MORE_BETS)),
  skipUntil(
    action$.pipe(
      ofType(round.set),
      pluck('payload', 'roundStatus'),
      distinctUntilChanged(),
      filter(isBetStates),
      take(1)
    )
  ),
  map(() => createPlaceBetRequestPayload(state$.value)),
  filter(length),
  flatMap((_actions) => concat(...rMap(of, _actions), of(betActions.bet.success())))
);

const betUndoEpic = (action$, state$) => action$.pipe(
  ofType(history.pop),
  map(() => validateBetUndo(null, state$.value)),
  flatMap((result) => {
    const actions = rMap(of, result.actions);
    return concat(...actions);
  })
);

const rebetEpic = (action$, state$) => action$.pipe(
  ofType(bet.rebet),
  map(() => validateRebet(null, state$.value)),
  flatMap((result) => {
    const actions = rMap(of, result.actions);
    return concat(...actions);
  })
);

const doubleEpic = (action$, state$) => action$.pipe(
  ofType(bet.double),
  map(() => validateDouble(null, state$.value)),
  flatMap((result) => {
    const actions = rMap(of, result.actions);
    return concat(...actions);
  })
);

export default combineEpics(
  placeBetEpic,
  totalBetEpic,
  betResetEpic,
  betRequestEpic,
  betUndoEpic,
  doubleEpic,
  rebetEpic,
);
