import { combineEpics, ofType, } from 'redux-observable';
import { filter, map, pluck, } from 'rxjs/operators';
import { PLACE_YOUR_BETS, SHUFFLE, } from '@ezugi/constants';
import { actions as bootstrapActions, } from '@ezugi/bootstrap';

import { abStatsSelector, } from '../../selectors/game';
import { getStats, JOKER_STATS_INITIAL_DATA, } from './utils';

const {
  socketActions: { socket, },
  gameActions: { game, },
} = bootstrapActions;

const jokerStatisticsEpic = (action$, state$) => action$.pipe(
  ofType(socket.message),
  pluck('payload'),
  filter(({ MessageType, }) => [ SHUFFLE, PLACE_YOUR_BETS, ].includes(MessageType)),
  map(({ MessageType, JokerStatistics, }) => game.set({
    stats: {
      joker: MessageType === SHUFFLE ? JOKER_STATS_INITIAL_DATA : getStats(JokerStatistics, true),
      ab: { ...abStatsSelector(state$.value), },
    },
  })),
);

export default combineEpics(jokerStatisticsEpic);
