import 'react-app-polyfill/ie11';
import 'core-js/es/set';
import 'core-js/es/map';
import 'core-js/es/weak-map';
import 'core-js/es/array';
import 'core-js/es/symbol';
import 'core-js/es/string';
import 'core-js/es/object';
import 'core-js/es/number';

import bootstrap from '@ezugi/bootstrap';
import config from './config';

bootstrap(config);
