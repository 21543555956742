import { prop, } from 'ramda';
import BET_TYPES, { SIDEBETS_LIST, } from '../../../constants/betTypes';

export const PAYOUTS_INDEX_MAP = {
  [BET_TYPES.ANDAR_FIRST]: 1,
  [BET_TYPES.BAHAR_FIRST]: 2,
  [BET_TYPES.ANDAR_SECOND]: 3,
  [BET_TYPES.BAHAR_SECOND]: 4,
  [SIDEBETS_LIST.ONE_TO_FIVE]: 5,
  [SIDEBETS_LIST.SIX_TO_TEN]: 6,
  [SIDEBETS_LIST.ELEVEN_TO_FIFTEEN]: 7,
  [SIDEBETS_LIST.SIXTEEN_TO_TWENTYFIVE]: 8,
  [SIDEBETS_LIST.TWENTYSIX_TO_THIRTY]: 9,
  [SIDEBETS_LIST.THIRTYONE_TO_THIRTYFIVE]: 10,
  [SIDEBETS_LIST.THIRTYSIX_TO_FOURTY]: 11,
  [SIDEBETS_LIST.FOURTYONE_OR_MORE]: 12,
};

function hasPayout(payouts, payoutOption) {
  return !!+payouts[payoutOption];
}

function formatPayout(p, payouts) {
  const formattedPayout = [
    PAYOUTS_INDEX_MAP[BET_TYPES.ANDAR_SECOND],
    PAYOUTS_INDEX_MAP[BET_TYPES.BAHAR_FIRST],
    PAYOUTS_INDEX_MAP[BET_TYPES.BAHAR_SECOND],
  ].includes(+p.IndexID) // OTTAB ANDAR, BAHAR bets are showing payouts in a special format
    ? `${prop(hasPayout(payouts, 'option1') ? 'option1' : 'option2', payouts)}-${payouts.option3}:1` // 0.25-1:1
    : `${payouts.option1}:1`;
  return formattedPayout === '1-1:1' ? '1:1' : formattedPayout; // condense 1-1:1 to 1:1
}

export function formatPayouts(list = []) {
  const formatList = list.reduce(
    (acc, p) => {
      const payouts = JSON.parse(p.payout);
      return { ...acc, [+p.IndexID]: formatPayout(p, payouts), };
    },
    {}
  );
  return formatList;
}
